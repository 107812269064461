@keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .bg-gradient-to-r {
    animation: gradientBG 15s ease infinite;
    background-size: 200% 200%;
  }
  